// Dependency
import React, { useRef} from "react"
import "./codeblock.scss"


const CodeBlock = props => {

  const scriptRef = useRef(null)

  // useEffect(() => {
  //   if (scriptRef.current) {
  //     const scriptTags = scriptRef.current.getElementsByTagName("script")
  //     for (var index = 0; index < scriptTags.length; index++) {
  //       window.eval(scriptTags[index].text)
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (

    <div className="codeblock-section">
      <div ref={scriptRef} dangerouslySetInnerHTML={{__html: props.blok.markup}}></div>
    </div>
  )
}


export default CodeBlock
